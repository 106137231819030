<template>
  <div>
    <v-text-field
      v-model="translationInUserCulture"
      :error-messages="generalError"
      :dense="dense"
      :label="label"
      :placeholder="placeholder"
      :rules="rules"
      :readonly="disabled"
      :counter="counter"
      ref="current-culture-name"
    >
      <template v-slot:prepend>
        <div v-if="showTranslations">
          <div class="harmonized-width">
            <v-chip color="primary">{{$t('t.' + currentCulture)}}</v-chip>
          </div>
        </div>
        <slot name="prepend" />
      </template>
      <template v-slot:append>
        <slot name="append" />
      </template>
      <template v-slot:append-outer>
        <v-badge
          color="error"
          :icon="$icon('i.Alert')"
          left
          :value="generalError.length"
        >
          <v-btn
            class="ml-n1"
            small
            icon
            @click="toggleTranslations"
          >
            <icon-tooltip
              small
              color="primary"
              :icon-name="'i.Cultures'"
              :tooltip-text="$t('t.ShowTranslations')"
            />
          </v-btn>
        </v-badge>
        <slot name="append-outer" />
      </template>
    </v-text-field>
    <div v-show="showTranslations">
      <v-text-field
        v-for="culture in otherCultures"
        :value="getTranslation(culture)"
        @input="updateTranslations(culture, $event)"
        :error-messages="cultureSpecificError[culture]"
        :dense="dense"
        :key="culture"
        :label="label"
        :placeholder="placeholder"
        :rules="rules"
        :disabled="disabled"
        :readonly="disabled"
        :counter="counter"
        ref="other-culture-names"
      >
        <template v-slot:prepend>
          <div class="harmonized-width">
            <v-chip color="primary">{{$t('t.' + culture)}}</v-chip>
          </div>
          <slot name="prepend" />
        </template>
      </v-text-field>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    IconTooltip: () => import('@/components/icon-tooltip')
  },
  data () {
    return {
      lockedCulture: new Set(),
      showTranslations: false,
      systemTranslations: []
    }
  },
  async created () {
    const e = await this.$http().get('/core/v6/settings/cultures/ui')
    this.systemTranslations = e.data.cultures
  },
  mounted () {
    if (this.translations) {
      Object.keys(this.translations).filter(t => this.translations[t].length > 0).forEach(t => this.lockedCulture.add(t))
    }
  },
  methods: {
    closeTranslations () {
      this.showTranslations = false
    },
    getTranslation (culture) {
      return this.translations?.[culture] ?? ''
    },
    toggleTranslations () {
      this.showTranslations = !this.showTranslations
    },
    updateTranslations (culture, value) {
      this.lockedCulture.add(culture)
      const unusedCultures = culture === this.currentCulture && this.isNew ? this.lodash.difference(this.systemTranslations, [...this.lockedCulture]) : []
      this.$emit('update:translations', Object.assign({}, this.translations, ...[...unusedCultures, culture].map(culture => ({ [culture]: value }))))
    }
  },
  computed: {
    generalError () {
      // If we already have an error on the current culture name field, do not add other errors
      if (this.$refs?.['current-culture-name']?.errorBucket?.length) {
        return []
      }

      // If we already have an custom error on the current culture name field, do not add other errors
      if (this.cultureSpecificError[this.currentCulture]?.length) {
        return this.cultureSpecificError[this.currentCulture]
      }

      // If the other translations aren't displayed
      if (!this.showTranslations) {
        // Fetch their error messages
        const aggregatedErrors = this.$refs?.['other-culture-names']?.map(f => f.errorBucket.map(m => this.$t('t.' + f.$vnode.key) + ': ' + m)) ?? []
        return this.customErrors.map(e => this.$t('t.' + e.cultures[0]) + ': ' + e.message).concat(...aggregatedErrors)
      } else {
        // If we display the other translation, display it's custom error messages if any
        return this.cultureSpecificError[this.culture] ?? []
      }
    },
    cultureSpecificError () {
      const errorByCultures = Object.fromEntries(this.lodash.cloneDeep(this.systemTranslations).map(c => [c, []]))
      this.customErrors.forEach(e => e.cultures.filter(c => this.systemTranslations.includes(c)).forEach(c => errorByCultures[c].push(e.message)))
      return errorByCultures
    },
    translationInUserCulture: {
      get () {
        return (this.translations && this.translations[this.currentCulture]) || ''
      },
      set (value) {
        this.updateTranslations(this.currentCulture, value)
      }
    },
    otherCultures () {
      return this.lodash.difference(this.systemTranslations, [this.$store.getters.currentUser.culture])
    },
    currentCulture () {
      return this.$store.getters.currentUser.culture
    }
  },
  props: {
    customErrors: {
      default: () => [],
      type: Array
    },
    dense: Boolean,
    disabled: Boolean,
    isNew: Boolean,
    label: String,
    placeholder: String,
    rules: Array,
    counter: Number,
    translations: Object
  }
}
</script>

<style lang="stylus" scoped>
.harmonized-width
  width 10em

// Fix a rendering bug after compilation
>>> .v-badge__badge .v-icon
  color inherit !important
  font-size inherit !important
</style>
